<template></template>

<script>
    export default {
        /**Componente usado cuando el usuario cierra la sesión en el sistema */
        created() {
            this.$store.dispatch("destroyToken").then(response => {
                this.$router.push({
                    name: "login"
                });
            });
        }
    };
</script>